/**************. website.css ***********************/
body {
  background-color: #f1f1f1; }

#top_menu_items {
  padding-top: 30px; }

.user_account {
  padding-top: 35px; }

#top_menu_items {
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: -1px; }

#top_menu_items > li {
  display: inline;
  padding-left: 30px;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  margin: .25em 0;
  padding: 0 1em;
  text-align: center;
  border-left: 1px solid #ccc; }

.row {
  position: relative; }

.slider_img {
  width: 100%;
  height: 300px !important; }

.how_it_works_img {
  width: 300px;
  height: 145px; }

.right-side {
  height: 155px;
  margin-left: -27px; }

.align_text_center {
  text-align: center; }

.expired_auction_div, .live_auction_div, .open_auction_div {
  border-width: 2px;
  border-style: groove;
  border-color: #D7D7D7 !important;
  border-top: none !important; }

.expired_auction_div {
  height: 360px; }

.live_car_type_div, .open_car_type_div, .expired_car_type_div {
  height: 60px !important;
  text-align: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; }

.live_auctions h4 span, .open_auctions h4 span, .expired_auctions h4 span {
  color: white;
  font-size: 25px;
  font-weight: bold;
  margin: 10px 0 20px; }

.live_auctions h4, .open_auctions h4, .expired_auctions h4 {
  width: 100%;
  text-align: center;
  border-bottom: 1px dashed white;
  line-height: 0.1em;
  margin: 10px 0 20px;
  border-width: 2px; }

h4 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px; }

h4 span {
  background-color: #f1f1f1;
  margin: 10px 0 20px; }

.car_type_title {
  font-size: 20px;
  color: white; }

.expired_auction_content {
  color: #a7a7a7;
  text-align: left;
  padding-left: 20px;
  padding-top: 10px; }

.expired_auction_img {
  position: relative;
  width: 90%;
  height: 150px !important;
  background-color: #f1f1f1; }

.top_menu a {
  color: black; }

a:hover {
  text-decoration: none; }

.testmonials_div {
  color: white;
  text-align: center; }

.person_div {
  height: 250px;
  width: 290px;
  white-space: normal; }

.person_img_div {
  align: center; }

.person_img {
  width: 60px;
  height: 60px !important;
  border-radius: 50%; }

.person_words {
  text-align: center;
  color: white;
  white-space: normal; }

.partners_div {
  text-align: center; }

.partner tr td {
  background-color: #cdcdcd;
  width: 15%;
  height: 15%;
  text-align: center; }

.partner_img {
  width: 85px;
  height: 85px; }

.footer_div a {
  color: black; }

.footer_table {
  width: 100%;
  background-color: #f1f1f1; }

.footer_table tr td {
  width: 25%;
  height: 200px;
  padding-left: 15px;
  vertical-align: top; }

.footer_list {
  list-style: none; }

.social_media_img {
  border-radius: 50%;
  width: 30px;
  height: 30px; }

.last_footer {
  background-color: gray;
  color: white;
  text-align: center;
  height: 40px; }

hr {
  border-top: 1px solid gray; }

.auction_div {
  /*border-style: solid;*/ }

/*  .auction_title
        {
             padding-left: 15px;
            border-bottom-left-radius:20px;
            border-bottom-right-radius:20px;
            color:white;
            }*/
.auction_content_table {
  border-collapse: separate;
  border-spacing: 30px;
  /* cellspacing*/ }

.join_auction_div {
  background-color: #3390cF;
  padding-left: 15px;
  border-radius: 15px;
  color: white;
  text-align: center; }

.select_seats {
  background-color: white;
  color: #3390cF;
  border: 0;
  /*Removes border*/ }

.pay_now_btn {
  background-color: #F9990F;
  border-radius: 25px;
  color: white;
  border: 2px solid #F9990F;
  width: 100px;
  height: 30px;
  margin-left: 50%;
  margin-top: 5%; }

.bidding_div_title, .available_seats_title {
  background-color: #3390cF;
  height: 40px;
  color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center; }

.auction_details_table {
  border-collapse: separate;
  border-spacing: 15px !important;
  /* cellspacing*/
  border-collapse: collapse; }

.auction_details_table tr {
  border-bottom: 1pt solid #000; }

.auction_details_table tr td {
  vertical-align: top;
  width: 200px; }

.bid_buttons {
  width: 100%;
  height: 20px !important;
  border-radius: 10px;
  color: white; }

.inc_btn {
  background-color: #3AABDA;
  border: 2px solid #3AABDA; }

.bid_btn {
  background-color: #F9990F;
  border: 2px solid #F9990F; }

/*.auction_title
        {
             background-color: #3390cF;
             padding-left: 15px;
            border-bottom-left-radius:20px;
            border-bottom-right-radius:20px;
            color:white;
            }*/
.auction_content_table {
  border-collapse: separate;
  border-spacing: 30px;
  /* cellspacing*/ }

.join_auction_div {
  background-color: #3390cF;
  padding-left: 15px;
  border-radius: 15px;
  color: white;
  text-align: center; }

.select_seats {
  background-color: white;
  color: #3390cF;
  border: 0;
  /*Removes border*/ }

.pay_now_btn {
  background-color: #F9990F;
  border-radius: 25px;
  color: white;
  border: 2px solid #F9990F;
  width: 100px;
  height: 30px; }

.bidding_div_title, .available_seats_title {
  background-color: #3390cF !important;
  height: 40px;
  color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center; }

.bidding_list_div {
  color: gray;
  text-align: center; }

.auction_details_table {
  border-collapse: separate;
  border-spacing: 15px !important;
  /* cellspacing*/
  border-collapse: collapse; }

.auction_details_table tr {
  border-bottom: 1pt solid #000; }

.auction_details_table tr td {
  vertical-align: top;
  width: 200px; }

.carousel-control.left, .carousel-control.right {
  background-image: none; }

.img-responsive {
  width: 170px;
  height: 70px; }

.multi-item-carousel .carousel-inner > .item {
  transition: 500ms ease-in-out left; }

.multi-item-carousel .carousel-inner .active.left {
  left: -33%; }

.multi-item-carousel .carousel-inner .active.right {
  left: 33%; }

.multi-item-carousel .carousel-inner .next {
  left: 33%; }

.multi-item-carousel .carousel-inner .prev {
  left: -33%; }

@media all and (transform-3d), (-webkit-transform-3d) {
  .multi-item-carousel .carousel-inner > .item {
    transition: 500ms ease-in-out all;
    backface-visibility: visible;
    transform: none !important; } }

.multi-item-carousel .carouse-control.left, .multi-item-carousel .carouse-control.right {
  background-image: none; }

.left_align {
  text-align: left; }

.live_auctions_background {
  background-color: #2c71b8; }

.upcoming_auctions_backgroud {
  background-color: #bb6c29; }

.completed_auctions_background {
  background-color: gray; }

.titles_background {
  background-color: #3390cf; }

.auction_container {
  margin-top: -13px; }

/***************************** car4pennies_styles.css **************************/
/*main rules*/
body {
  font-family: 'Open Sans','Catamaran',sans-serif;
  background-color: #F4F4F4; }

.title-section-font {
  font-size: 2.5vw; }

.desc-title-section-font {
  font-size: 1.8vw; }

.content-section-font {
  font-size: 1.5vw; }

/*main rules*/
/* start navigation bar */
.navbar {
  background-color: #ffffff; }

.navbar-nav {
  margin-top: 3px; }

.tol_free_nav {
  padding-top: 15px; }

.tol_free_nav label {
  color: #00C8FA; }

#logo {
  height: 4vw; }

#navbar_elements {
  padding-top: 2vw; }

a.navbar_link:hover {
  border-bottom: 2px solid #65D2EC; }

.navbar_link {
  height: 3vw; }

.navbar_link label {
  color: #65D2EC; }

/* end navigation bar */
/**************************************************************************************/
/* home*/
.search-part {
  margin-top: 2em; }

.search-part .title, .description {
  margin-left: -15px; }

.error-message {
  color: #ffffff;
  /*font-size:2vw;*/
  padding-top: 2vw;
  padding-bottom: 1vw;
  text-align: center; }

/*home*/
/*************************************************************************************/
.dropdown_button div select {
  width: 100%;
  background-color: #B6B6B6;
  border: 1px solid #EFEFEF;
  color: #FAF8F8; }

.gradiant_background {
  background: #00ABE9;
  background: linear-gradient(#00ABE9, #534DE6); }

.white_line {
  border: 5px solid #ffffff; }

.slider {
  color: #ffffff; }

.slider img {
  height: 250px;
  width: 200px;
  padding-top: 70px; }

.slider .home_slider_image {
  text-align: center; }

.my_auction_user_account img {
  height: 250px;
  width: 200px; }

.car_title {
  text-align: center; }

.car_title div {
  font-size: 100%; }

.car_property {
  text-align: left; }

.thin_white_line {
  border: 1px solid #ffffff; }

.contact_us_form div {
  text-align: center;
  font-size: 2vw; }

.contact_us_form div input {
  width: 100%;
  border: 2px solid #CDCACA;
  border-radius: 10px;
  height: 50px;
  background-color: #B6B6B6;
  color: #ffffff; }

.contact_us_form div textarea {
  width: 100%;
  border: 2px solid #CDCACA;
  border-radius: 10px;
  height: 45%;
  background-color: #B6B6B6; }

.car_property span {
  font-size: 1.2vw; }

.gray_button {
  text-align: center; }

.gray_button a {
  background: #B4B4B4;
  background: linear-gradient(to right, #B4B4B4, #909090);
  width: 100%;
  border-radius: 20px; }

.gray_button a span {
  font-size: 1.1vw;
  color: #ffffff; }

.section_gray_title div {
  text-align: center; }

.section_gray_title div label {
  font-size: 2.5vw; }

.section_gray_title_description div {
  text-align: center; }

.section_gray_title_description div label {
  /*font-size: 2vw;*/ }

.Warranty_and_Liability_section div {
  padding-right: 5px;
  padding-left: 5px; }

.Warranty_and_Liability_section div div {
  border: 2px solid black;
  padding-left: 10px;
  border-radius: 10px; }

.Warranty_and_Liability_section div .padding {
  padding-top: 17px;
  padding-bottom: 18px; }

.Warranty_and_Liability_section div div span {
  font-size: 1.8vw; }

.iframe_div {
  background: linear-gradient(to bottom, #B5B5B5 0%, #B5B5B5 50%, #F4F4F4 50%, #F4F4F4 100%); }

.iframe_div div div div {
  text-align: center; }

.iframe_div iframe {
  width: 100%;
  height: 500px; }

.gray_line {
  border: 5px solid #C1C1C1; }

.how_it_work_section .image img {
  width: 80%;
  height: 25%; }

.how_it_work_section .description label {
  font-size: 2.1vw; }

.how_it_work_section .description span {
  color: #0EC5FE;
  font-size: 1.8vw; }

.partiners_images div {
  text-align: center; }

.partiners_images div img {
  height: 10%; }

.gray_background {
  background-color: #B6B6B6; }

/*first section in home page */
.first_section {
  padding-top: 0px;
  background-color: #B6B6B6;
  border: 5px solid #B6B6B6; }

.first_section img {
  height: 450px;
  width: 600px;
  padding-top: 70px; }

.first_section .description div label {
  letter-spacing: 2px;
  /*font-size: 1.5vw;*/ }

/*lesaaaaaaaaaaaaaaaaaaaaaaa*/
.dropdown {
  text-align: center; }

.dropdown button {
  width: 100%;
  background-color: #B6B6B6;
  border: 2px solid #EFEFEF; }

.dropdown button .row .col-xs-4 span {
  font-size: 1.8vw;
  color: #FAF8F8; }

/*lsaaaaaaaaaa*/
#grad {
  background: #0B9DE8;
  /* For browsers that do not support gradients */
  /* For Safari 5.1 to 6.0 */
  /* For Opera 11.1 to 12.0 */
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(#0B9DE8, #2A7BE8);
  /* Standard syntax */
  width: 50px; }

#grad2 {
  background: #3273E8;
  /* For browsers that do not support gradients */
  /* For Safari 5.1 to 6.0 */
  /* For Opera 11.1 to 12.0 */
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(#3273E8, #5150E6);
  /* Standard syntax */
  width: 50px; }

#grad3 {
  background: #0E9BE9;
  /* For browsers that do not support gradients */
  /* For Safari 5.1 to 6.0 */
  /* For Opera 11.1 to 12.0 */
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(#0E9BE9, #4D53E6);
  /* Standard syntax */
  width: 50px; }

body {
  background-color: #F4F4F4; }

.navbar_link label {
  color: #65D2EC; }

.gradiant_background {
  background: #00ABE9;
  background: linear-gradient(#00ABE9, #534DE6); }

.section_blue_title div, .section_blue_title_description div {
  text-align: center; }

.section_blue_title div label {
  font-size: 2.5vw;
  color: #ffffff;
  text-align: center; }

.section_blue_title div label a {
  font-size: 2.5vw;
  color: #ffffff;
  text-align: center; }

.section_blue_title_description div label {
  font-size: 1.8vw;
  color: #ffffff;
  text-align: center; }

.section_blue_title_label {
  font-size: 2.5vw;
  color: #ffffff;
  text-align: center; }

.section_blue_title_link {
  font-size: 2.5vw;
  color: #ffffff;
  text-align: center; }

.section_blue_title_link:hover {
  color: yellow; }

.section_blue_title_description_label {
  font-size: 1.8vw;
  color: #ffffff;
  text-align: center; }

.section_blue_title_in_bale_background div, .section_blue_title_description_in_bale_background div {
  text-align: center; }

.section_blue_title_in_bale_background_label {
  font-size: 2.5vw;
  color: black;
  text-align: center; }

.section_blue_title_description_in_bale_background_label {
  font-size: 1.8vw;
  color: black;
  text-align: center; }

.title_text {
  font-size: 2.5vw;
  color: #ffffff; }

.white_line {
  border: 5px solid #ffffff; }

.black_line {
  border: 5px solid black; }

.slider {
  color: #ffffff; }

.slider img {
  height: 250px;
  width: 200px;
  padding-top: 70px; }

.my_car_user_account img {
  height: 200px;
  width: 100%; }

.car_title {
  text-align: center; }

.car_property {
  text-align: left; }

.car_property span {
  font-size: 1.1vw; }

.gray_line {
  border: 5px solid #C1C1C1; }

.dark_gray_line {
  border: 5px solid #959595; }

iframe {
  background-color: #E0E0E0;
  width: 100%;
  height: 500px; }

.testmonials_image {
  text-align: center; }

.testmonials_image img {
  border-radius: 100%;
  border: 2px solid #0EC5FE;
  width: 75%;
  height: 26%; }

.name_of_image {
  text-align: center;
  padding-top: 10%; }

.name_of_image label {
  font-size: 1.8vw; }

.description_of_image {
  padding-top: 100%;
  text-align: center; }

.description_of_image span {
  color: #0EC5FE;
  font-size: 1.1vw; }

.partiners_images div {
  text-align: center; }

.partiners_images div img {
  width: 80%;
  height: 10%; }

.auction_title {
  text-align: center;
  padding-top: 150px; }

.auction_title label {
  font-size: 2.5vw;
  color: #000000; }

.dark_dark_gray_background {
  background-color: #B6B6B6; }

.light_gray_background {
  background-color: #F4F4F4; }

/*lsaaaaaaaaaa*/
.dropdown {
  text-align: center; }

.dropdown select {
  width: 100%;
  background-color: #B6B6B6;
  border: 2px solid #EFEFEF; }

.dropdown select option {
  font-size: 2.5vw;
  color: #FAF8F8; }

/*lsaaaaaaaaaa*/
.dark_gray_line {
  border: 5px solid #959595; }

.single_auction_car {
  width: 100%; }

#Controls_gradiant {
  background: red;
  background: linear-gradient(to right, #B6B6B6, #B6B6B6);
  width: 100px; }

.single_auction_slider {
  text-align: center;
  vertical-align: middle;
  height: 6vw; }

.single_auction_slider div {
  border: 5px solid #C0C0C0;
  border-radius: 20px;
  vertical-align: middle;
  height: 100%; }

.auction_img_in_slider {
  vertical-align: middle;
  width: 100%; }

/*.single_auction_slider div img
{
  //height:150px;
  vertical-align:middle;
}*/
.black_title div {
  text-align: center; }

.black_title div label {
  font-size: 2.1vw;
  color: #000000; }

.light_gray {
  background-color: #F4F4F4; }

.gray_backgroun {
  background-color: #B6B6B6; }

.white_line {
  border: 5px solid #ffffff; }

.div_height_1 {
  height: 150px; }

.div_height_2 {
  height: 40px; }

.div_height_3 {
  height: 50px; }

.div_height_4 {
  height: 100px; }

.div_height_5 {
  height: 20px; }

.div_height_6 {
  height: 30px; }

.standard_height {
  height: 20px; }

.large_height {
  height: 150px; }

.medium_height {
  height: 100px; }

.small_height {
  height: 30px; }

.very_small_height {
  height: 10px; }

/*start  partials/search.blade.php  style*/
.search_part_slider-img-title {
  color: #ffffff;
  font-size: 2.5vw; }

/*end partials/search.blade.php  style*/
/*Start car_info.blade.php*/
.information_content {
  margin-top: 30px; }

.SUMMARY_content .col-xs-7 {
  border: 2px solid black;
  border-radius: 5px;
  font-size: 25px; }

.PROOF_content .col-xs-4 {
  font-size: 30px;
  text-align: center; }

.ATTACHEMENT_content .col-xs-7 {
  font-size: 30px;
  text-align: left; }

.keyfacts_title {
  text-align: center; }

.keyfacts_title div span {
  font-size: 20px;
  color: #4E4E4E; }

.KEYFACTS_content div div {
  color: #00CBFB;
  font-size: 20px; }

.Vehicle_Basic_Information_content {
  color: black; }

.black-line {
  border: 1px solid black; }

.third-column-keyfacts {
  padding-top: 40px; }

.keyfact-img {
  padding-top: 40px; }

.keyfact-img img {
  width: 100%; }

/*End car_info.blade.php*/
.list_div div ul li {
  font-size: 2.5vw; }

.list_div div ul li p {
  font-size: 2vw; }

.text {
  font-size: 1.1vw; }

.caption {
  position: absolute;
  bottom: 50%;
  top: 25%;
  left: 0;
  width: 100%;
  color: white;
  font-size: 2.5vw;
  font-weight: bold; }

.black_border {
  border: 3px solid black;
  border-radius: 5px; }

.user_account_links div {
  font-size: 1.2vw;
  color: black;
  text-align: center; }

.user_account_links div a:link {
  color: black; }

.user_account_links div a:visited {
  color: black; }

.user_account_links div a:hover {
  color: #00AFF0; }

.user_account_links div a:active {
  color: #00AFF0; }

.profile_data div img {
  width: 150px;
  height: 170px;
  border-radius: 50%;
  border: 4px solid #00AFF0; }

.profile_data div label {
  font-size: 2.5vw; }

.user_acount_form div form .form-group div span {
  font-size: 1.5vw; }

.user_acount_form div form .form-group div input {
  border: 1px solid black; }

.information_links {
  border: 2px solid black;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  overflow: hidden; }

.information_links .row .col-xs-3 {
  text-align: center; }

.information_links .row .col-xs-3 div label {
  font-size: 1.8vw;
  cursor: pointer; }

.information_links .row .col-xs-3 div a:link {
  color: #000000; }

.information-content {
  margin-top: 20px; }

/*Footer*/
.payment_img_master_amex {
  width: 7vw;
  height: 4vw; }

.payment_img_paypal_visa {
  width: 5.5vw;
  height: 3vw; }

.payment_icon {
  width: 5.5vw; }

span {
  margin: 0px;
  padding: 0px; }

/* valign */
th, td {
  vertical-align: top; }

/* Collected by yasser from other files */
.hiw_section_text {
  font-size: 1.7vw; }

.small_separator {
  height: 10px; }

.large_separator {
  height: 30px; }

.btn_font_fize {
  font-size: 1.8vw; }

.form-label-adv-search {
  color: #ffffff;
  font-size: 1.8vw; }

.form-field-adv-search {
  font-size: 1.5vw; }

.div_height_7 {
  height: 15px; }

.form-field-textbox-adv-search {
  width: 100%;
  font-size: 1.5vw;
  text-align: center; }

.search_message {
  font-size: 2.1vw;
  margin-top: 100px;
  text-align: center;
  color: #ffffff; }

.box-space {
  padding-right: 3px;
  padding-left: 3px; }

/******************* Buttons ************************/
.tbx_base_button, .tbx_button, .yellow_button, .auction_tablet_button, .blue_button, .tbx_skinny_button, .blue_button_2, .yellow_button_2 {
  border-radius: 2vw;
  font-family: Arial;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  width: 100%; }

.tbx_base_button a, .tbx_button a, .yellow_button a, .auction_tablet_button a, .blue_button a, .tbx_skinny_button a, .blue_button_2 a, .yellow_button_2 a {
  color: #ffffff; }

.tbx_button, .yellow_button, .auction_tablet_button, .blue_button {
  font-size: 1.5vw;
  padding-top: 0.6em;
  padding-bottom: 0.6em; }

.tbx_skinny_button, .blue_button_2, .yellow_button_2 {
  font-size: 1.5vw;
  padding-top: 0.1em;
  padding-bottom: 0.1em; }

.yellow_button, .auction_tablet_button {
  background: #FCBC30;
  background-image: linear-gradient(to bottom, #FCBC30, #FDD43C); }

.yellow_button:hover, .auction_tablet_button:hover {
  background: #FDD43C;
  background-image: linear-gradient(to bottom, #FDD43C, #FCBC30); }

.blue_button {
  background: #006BB2;
  background-image: linear-gradient(to bottom, #006BB2, #00CBFC); }

.blue_button:hover {
  background: #00CBFC;
  background-image: linear-gradient(to bottom, #00CBFC, #006BB2); }

/************************** Special buttons ***************************/
.blue_button_2 {
  border: 1px solid #00CBFC;
  background: #006BB2;
  background: linear-gradient(to right, #006BB2, #00CBFC); }

.blue_button_2:hover {
  background: #00CBFC;
  background-image: linear-gradient(to bottom, #00CBFC, #006BB2); }

.yellow_button_2 {
  border: 1px solid #FDD43C;
  background: #FCBC30;
  background: linear-gradient(to right, #FCBC30, #FDD43C); }

.yellow_button_2:hover {
  background: #FDD43C;
  background-image: linear-gradient(to bottom, #FDD43C, #FCBC30); }

.btn_on {
  background: #FCBB2F;
  /* For browsers that do not support gradients */
  /* For Safari 5.1 to 6.0 */
  /* For Opera 11.1 to 12.0 */
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #FCBB2F, #FDD43C);
  /* Standard syntax */
  font-size: 2.5vw;
  border-radius: 30px; }

.btn_off {
  background: #808080;
  /* For browsers that do not support gradients */
  /* For Safari 5.1 to 6.0 */
  /* For Opera 11.1 to 12.0 */
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #808080, #DDDBDB);
  /* Standard syntax */
  font-size: 2.5vw;
  border-radius: 30px; }

/******************* Home Page Search Partial ***************/
.arrow {
  border: 2px solid #F1ECEC;
  padding: 2px;
  background: #F1ECEC;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em; }

.arrow-down {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #F1ECEC; }

/*
@media screen and (max-width: 355px)
{
  .dropdown_button
  {
    width:455px
  }
   #region_id
  {
    max-width:100%;
  }
}
*/
.centered_text {
  text-align: center; }

.carousel-caption {
  position: relative;
  left: auto;
  right: auto; }

.home_slider_carsoul_image {
  position: relative;
  height: 32vw; }

.home_slider_carsoul_image img {
  width: 70%;
  margin: 0 auto; }

.home_slider_caption_text {
  color: #ffffff;
  font-size: 2.5vw; }

/******************* Auction Tablets ************************/
.auction_img {
  text-align: center;
  align: center;
  vertical-align: bottom;
  height: 10vw; }

.auction_img img {
  /*width: 200px;height: 200px;*/
  margin: 0px;
  padding-top: 0px;
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom; }

.auction_tablet_table {
  font-size: 1vw;
  width: 100%;
  border: 0; }

.auction_tablet_important_text {
  color: yellow; }

.auction_tablet_normal_text {
  color: #ffffff; }

.auction_tablet_caption_text {
  color: #ffffff; }

.auction_tablet_img {
  text-align: center;
  align: center;
  vertical-align: bottom;
  height: 10vw; }

.auction_tablet_img img {
  /*width: 200px;height: 200px;*/
  margin: 0px;
  padding-top: 20px;
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom; }

.auction_tablet_title {
  margin-top: 5%;
  color: #ffffff;
  min-height: 4vw;
  max-height: 4vw;
  height: 4vw;
  text-align: center;
  align: center;
  width: 95%; }

.auction_tablet_title div label {
  min-height: 3em;
  max-height: 3em;
  text-align: center;
  font-size: 1.5vw;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: ellipsis; }

.auction_tablet_button {
  font-size: 1.5vw; }

.inblack {
  color: black; }

.inBoldBlack {
  color: black;
  font-weight: bold; }

/*# sourceMappingURL=frontend_.css.map */
